<template>
  <div>
    <Investor_KYC_Response
      :form_id="id"
      v-if="form_unique_id === 'investor-kyc-2023-v1'"
    />
  </div>
</template>

<script>
import Investor_KYC_Response from "@/components/Investor_KYC_Response";

export default {
  props: ["id", "form_unique_id"],

  components: {
    Investor_KYC_Response,
  },
};
</script>
