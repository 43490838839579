<template>
  <div>
    <v-alert
      v-show="pageIsError"
      transition="fade-transition"
      color="error"
      type="error"
      style="z-index: 2; position: fixed; right: 15px; min-width: 300px"
    >
      An error occurred!
    </v-alert>

    <v-container v-if="pageIsLoading">
      <v-row>
        <v-col>
          <h3 class="text-center">
            <v-progress-circular indeterminate class="ml-3" color="primary" />
            Getting data . . .
          </h3>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="!pageIsLoading">
      <v-row justify="center">
        <v-col xs="12" lg="8">
          <v-card>
            <v-card-title class="d-flex justify-center">
              <div>
                {{ formData.title | capitalize }}
              </div>
            </v-card-title>

            <v-card-text
              v-for="(section, index) in filtered_sections"
              :key="section.id"
            >
              <h3 class="mb-5 white--text">{{ section.title }}</h3>

              <div v-for="response in section.frm_rsp_qs" :key="response.id">
                <!-- response that has no conditions -->
                <div
                  v-if="
                    section.title !== 'Sophisticated Declaration' &&
                    section.title !== 'Accounts and Source of Funds'
                  "
                  class="mb-5"
                >
                  <p class="mb-0 white--text">
                    {{ response.frm_q.title }}
                  </p>
                  {{ response.response }}

                  <div v-if="response.type === 'multiple choice'">
                    <div v-for="choice in response.frm_opts" :key="choice.id">
                      {{ choice.choice }}
                    </div>
                  </div>

                  <!-- follow up question response level 1 -->

                  <div
                    v-for="r1 in response.frm_rsp_qs"
                    :key="r1.id"
                    class="my-5 ml-5"
                  >
                    <p class="mb-0 white--text">
                      {{ r1.frm_q.title }}
                    </p>
                    {{ r1.response }}
                  </div>
                </div>

                <!-- response that has conditions -->

                <!-- this is for the individual sophisticated declaration section -->
                <div
                  v-if="
                    application_type_is_individual &&
                    section.title === 'Sophisticated Declaration' &&
                    response.frm_q.title === 'Individual Sophisticated Investor'
                  "
                  class="mb-5"
                >
                  <p class="white--text">{{ response.frm_q.title }}</p>

                  <!-- display the choices -->
                  <div
                    v-for="choice in response.frm_opts"
                    :key="choice.id"
                    class="mb-2"
                  >
                    {{ choice.choice }}
                  </div>
                </div>

                <!-- this is for the individual and also corporation sophisticated declaration section -->
                <div
                  v-if="
                    application_type_is_individual &&
                    application_type_is_also_corporation &&
                    section.title === 'Sophisticated Declaration' &&
                    response.frm_q.title ===
                      'Corporation Sophisticated Investor'
                  "
                  class="mb-5"
                >
                  <p class="white--text">{{ response.frm_q.title }}</p>

                  <!-- display the choices -->
                  <div
                    v-for="choice in response.frm_opts"
                    :key="choice.id"
                    class="mb-2"
                  >
                    {{ choice.choice }}
                  </div>
                </div>

                <!-- this is for the corporation sophisticated declaration section -->
                <div
                  v-if="
                    !application_type_is_individual &&
                    section.title === 'Sophisticated Declaration' &&
                    response.frm_q.title ===
                      'Corporation Sophisticated Investor'
                  "
                  class="mb-5"
                >
                  <p class="white--text">{{ response.frm_q.title }}</p>

                  <!-- display the choices -->
                  <div
                    v-for="choice in response.frm_opts"
                    :key="choice.id"
                    class="mb-2"
                  >
                    {{ choice.choice }}
                  </div>
                </div>

                <!-- this is for the individual accounts and source of funds section -->
                <div
                  v-if="
                    application_type_is_individual &&
                    !application_type_is_also_corporation &&
                    section.title === 'Accounts and Source of Funds' &&
                    response.frm_q.title ===
                      'Will you be lending money using personal funds?'
                  "
                  class="mb-5"
                >
                  <p class="mb-0 white--text">
                    {{ response.frm_q.title }}
                  </p>
                  {{ response.response }}

                  <!-- follow up question response level 1 -->
                  <div
                    class="ma-2 pa-2 rounded-lg"
                    style="background-color: #111"
                  >
                    <div
                      v-for="(r1, r1_index) in response.frm_rsp_qs"
                      :key="r1.id"
                      class="my-5 ml-5"
                    >
                      <div
                        v-if="
                          r1.frm_q.title ===
                          'Will you be lending money using personal funds?'
                        "
                      >
                        <span class="white--text">Type: {{ r1.response }}</span>

                        <!-- follow up question response level 2 -->
                        <div v-for="r2 in r1.frm_rsp_qs" :key="r2.id">
                          <span
                            v-if="r1.response === 'RRSP/TFSA'"
                            class="white--text"
                          >
                            Name of Trust Company:
                          </span>

                          <span
                            v-if="r1.response === 'Others'"
                            class="white--text"
                          >
                            Specification:
                          </span>

                          {{ r2.response }}
                        </div>

                        <v-divider
                          v-if="response.frm_rsp_qs.length !== r1_index + 1"
                          class="mt-5"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <!-- this is for the individual/corporation accounts accounts and source of funds section -->
                <div
                  v-if="
                    application_type_is_individual &&
                    application_type_is_also_corporation &&
                    section.title === 'Accounts and Source of Funds' &&
                    response.frm_q.title ===
                      'Will you be lending money using personal funds?'
                  "
                  class="mb-5"
                >
                  <p class="mb-0 white--text">
                    {{ response.frm_q.title }}
                  </p>
                  {{ response.response }}

                  <!-- follow up question response level 1 -->
                  <div
                    class="ma-2 pa-2 rounded-lg"
                    style="background-color: #111"
                  >
                    <div
                      v-for="(r1, r1_index) in response.frm_rsp_qs"
                      :key="r1.id"
                      class="my-5 ml-5"
                    >
                      <div
                        v-if="
                          r1.frm_q.title ===
                          'Will you be lending money using personal funds?'
                        "
                      >
                        <span class="white--text">Type: {{ r1.response }}</span>

                        <!-- follow up question response level 2 -->
                        <div v-for="r2 in r1.frm_rsp_qs" :key="r2.id">
                          <span
                            v-if="r1.response === 'RRSP/TFSA'"
                            class="white--text"
                          >
                            Name of Trust Company:
                          </span>

                          <span
                            v-if="r1.response === 'Others'"
                            class="white--text"
                          >
                            Specification:
                          </span>

                          {{ r2.response }}
                        </div>

                        <v-divider
                          v-if="response.frm_rsp_qs.length !== r1_index + 1"
                          class="mt-5"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  v-if="
                    application_type_is_individual &&
                    application_type_is_also_corporation &&
                    section.title === 'Accounts and Source of Funds' &&
                    response.frm_q.title ===
                      'Will you be lending money out of a corporation you own / control?'
                  "
                  class="mb-5"
                >
                  <p class="mb-0 white--text">
                    {{ response.frm_q.title }}
                  </p>
                  {{ response.response }}

                  <!-- follow up question response level 1 -->
                  <div
                    class="ma-2 pa-2 rounded-lg"
                    style="background-color: #111"
                  >
                    <div
                      v-for="(r1, r1_index) in response.frm_rsp_qs"
                      :key="r1.id"
                      class="my-5 ml-5"
                    >
                      <span class="mb-0 green--text">
                        Corporation Details #{{ r1_index + 1 }}
                      </span>

                      <!-- follow up question response level 2 -->
                      <div v-for="(r2, r2_index) in r1.frm_rsp_qs" :key="r2.id">
                        <span class="white--text">
                          {{ corporate_details_questions[r2_index] }} :
                        </span>

                        {{ r2.response }}
                      </div>

                      <v-divider
                        v-if="response.frm_rsp_qs.length !== r1_index + 1"
                        class="mt-5"
                      />
                    </div>
                  </div>
                </div>

                <!-- this is for the corporation accounts and source of funds section -->
                <div
                  v-if="
                    !application_type_is_individual &&
                    !application_type_is_also_corporation &&
                    section.title === 'Accounts and Source of Funds' &&
                    response.frm_q.title ===
                      'Will you be lending money out of a corporation you own / control?'
                  "
                  class="mb-5"
                >
                  <p class="mb-0 white--text">
                    {{ response.frm_q.title }}
                  </p>
                  {{ response.response }}

                  <!-- follow up question response level 1 -->
                  <div
                    class="ma-2 pa-2 rounded-lg"
                    style="background-color: #111"
                  >
                    <div
                      v-for="(r1, r1_index) in response.frm_rsp_qs"
                      :key="r1.id"
                      class="my-5 ml-5"
                    >
                      <span class="mb-0 green--text">
                        Corporation Details #{{ r1_index + 1 }}
                      </span>

                      <!-- follow up question response level 2 -->
                      <div v-for="(r2, r2_index) in r1.frm_rsp_qs" :key="r2.id">
                        <span class="white--text">
                          {{ corporate_details_questions[r2_index] }} :
                        </span>

                        {{ r2.response }}
                      </div>

                      <v-divider
                        v-if="response.frm_rsp_qs.length !== r1_index + 1"
                        class="mt-5"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <v-divider v-if="filtered_sections.length !== index + 1" />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col xs="12" lg="8">
          <v-btn @click="$router.go(-1)"> Back </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import API from "@/plugins/API";
import filters from "@/plugins/filters";

export default {
  props: ["form_id"],

  data: () => ({
    pageIsLoading: true,
    pageIsError: false,
    application_type_is_individual: false,
    application_type_is_also_corporation: false,
    formData: {},

    corporate_details_questions: [
      "Name",
      "Address",
      "Date of formation",
      "Shareholders directors offiers partners",
      "Type",
      "Type Specification",
      "What is the corporate structure",
      "What is the nature or purpose of corporation",
      "What is source of funds",
    ],
  }),

  computed: {
    filtered_sections() {
      // check sophisticated declaration if there are selected choices
      const sophisticated_declaration = this.formData.frm_sects.find(
        (section) => section.title === "Sophisticated Declaration"
      );

      const it_has_selected_sophisticated_declaration =
        sophisticated_declaration.frm_rsp_qs.some(
          (question) => question.frm_opts.length > 0
        );

      // check application type
      const initial_information = this.formData.frm_sects.find(
        (section) => section.title === "Initial Information"
      );

      const application_type = initial_information.frm_rsp_qs.find(
        (question) => question.frm_q.title === "Application type"
      );

      const selected_applicaiton_type = application_type.response;

      const result = this.formData.frm_sects
        .map((section) => {
          if (
            selected_applicaiton_type ===
              "I am applying on behalf of a corporation that is primarily in the business of lending money via mortgages" ||
            selected_applicaiton_type ===
              "Mortgage brokerage, Mortgage Investment Company, Mortgage Investment Entity, Etc."
          ) {
            if (
              section.title === "Investment Knowledge" ||
              section.title === "Broker Declaration" ||
              section.title === "Liquidity"
            ) {
              return null;
            }
          }

          if (
            it_has_selected_sophisticated_declaration &&
            section.title === "Financial Situation"
          ) {
            return null;
          }

          return section;
        })
        .filter(Boolean);

      return result;
    },
  },

  methods: {
    handle_application_type() {
      // check application type
      const found_section = this.formData.frm_sects.find(
        (section) => section.title === "Initial Information"
      );

      const found_question = found_section.frm_rsp_qs.find(
        (question) => question.frm_q.title === "Application type"
      );

      const selected_applicaiton_type = found_question.response;

      // set whether it is an individual or corporation

      if (
        selected_applicaiton_type ===
        "I am an individual who invests in Mortgages with only personal cash, RRSP/TFSA"
      ) {
        this.application_type_is_individual = true;
      } else if (
        selected_applicaiton_type ===
        "I am an individual who invests in mortgages with cash, RRSP/TFSA, AND own/control corporations that invest my money"
      ) {
        this.application_type_is_individual = true;
        this.application_type_is_also_corporation = true;
      } else {
        this.application_type_is_individual = false;
      }
    },

    async readData() {
      try {
        const form_data = await API().get(
          `api/form/read_form_response?id=${this.form_id}`
        );
        console.log(form_data);

        if (form_data) {
          this.formData = form_data.data.frm;
          console.log("Form Data: ", this.formData);

          this.handle_application_type();

          console.log(
            "application type: ",
            this.application_type_is_individual
          );
          console.log(
            "is it also a corporation: ",
            this.application_type_is_also_corporation
          );

          this.pageIsLoading = false;
        }
      } catch (error) {
        console.log(error);
        this.pageIsError = true;
        setTimeout(() => {
          this.pageIsError = false;
        }, 2000);
      }
    },
  },

  filters: {
    ...filters,
  },

  mounted() {
    this.readData();
  },
};
</script>
