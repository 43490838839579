var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(_vm.pageIsError),expression:"pageIsError"}],staticStyle:{"z-index":"2","position":"fixed","right":"15px","min-width":"300px"},attrs:{"transition":"fade-transition","color":"error","type":"error"}},[_vm._v(" An error occurred! ")]),(_vm.pageIsLoading)?_c('v-container',[_c('v-row',[_c('v-col',[_c('h3',{staticClass:"text-center"},[_c('v-progress-circular',{staticClass:"ml-3",attrs:{"indeterminate":"","color":"primary"}}),_vm._v(" Getting data . . . ")],1)])],1)],1):_vm._e(),(!_vm.pageIsLoading)?_c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"xs":"12","lg":"8"}},[_c('v-card',[_c('v-card-title',{staticClass:"d-flex justify-center"},[_c('div',[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.formData.title))+" ")])]),_vm._l((_vm.filtered_sections),function(section,index){return _c('v-card-text',{key:section.id},[_c('h3',{staticClass:"mb-5 white--text"},[_vm._v(_vm._s(section.title))]),_vm._l((section.frm_rsp_qs),function(response){return _c('div',{key:response.id},[(
                  section.title !== 'Sophisticated Declaration' &&
                  section.title !== 'Accounts and Source of Funds'
                )?_c('div',{staticClass:"mb-5"},[_c('p',{staticClass:"mb-0 white--text"},[_vm._v(" "+_vm._s(response.frm_q.title)+" ")]),_vm._v(" "+_vm._s(response.response)+" "),(response.type === 'multiple choice')?_c('div',_vm._l((response.frm_opts),function(choice){return _c('div',{key:choice.id},[_vm._v(" "+_vm._s(choice.choice)+" ")])}),0):_vm._e(),_vm._l((response.frm_rsp_qs),function(r1){return _c('div',{key:r1.id,staticClass:"my-5 ml-5"},[_c('p',{staticClass:"mb-0 white--text"},[_vm._v(" "+_vm._s(r1.frm_q.title)+" ")]),_vm._v(" "+_vm._s(r1.response)+" ")])})],2):_vm._e(),(
                  _vm.application_type_is_individual &&
                  section.title === 'Sophisticated Declaration' &&
                  response.frm_q.title === 'Individual Sophisticated Investor'
                )?_c('div',{staticClass:"mb-5"},[_c('p',{staticClass:"white--text"},[_vm._v(_vm._s(response.frm_q.title))]),_vm._l((response.frm_opts),function(choice){return _c('div',{key:choice.id,staticClass:"mb-2"},[_vm._v(" "+_vm._s(choice.choice)+" ")])})],2):_vm._e(),(
                  _vm.application_type_is_individual &&
                  _vm.application_type_is_also_corporation &&
                  section.title === 'Sophisticated Declaration' &&
                  response.frm_q.title ===
                    'Corporation Sophisticated Investor'
                )?_c('div',{staticClass:"mb-5"},[_c('p',{staticClass:"white--text"},[_vm._v(_vm._s(response.frm_q.title))]),_vm._l((response.frm_opts),function(choice){return _c('div',{key:choice.id,staticClass:"mb-2"},[_vm._v(" "+_vm._s(choice.choice)+" ")])})],2):_vm._e(),(
                  !_vm.application_type_is_individual &&
                  section.title === 'Sophisticated Declaration' &&
                  response.frm_q.title ===
                    'Corporation Sophisticated Investor'
                )?_c('div',{staticClass:"mb-5"},[_c('p',{staticClass:"white--text"},[_vm._v(_vm._s(response.frm_q.title))]),_vm._l((response.frm_opts),function(choice){return _c('div',{key:choice.id,staticClass:"mb-2"},[_vm._v(" "+_vm._s(choice.choice)+" ")])})],2):_vm._e(),(
                  _vm.application_type_is_individual &&
                  !_vm.application_type_is_also_corporation &&
                  section.title === 'Accounts and Source of Funds' &&
                  response.frm_q.title ===
                    'Will you be lending money using personal funds?'
                )?_c('div',{staticClass:"mb-5"},[_c('p',{staticClass:"mb-0 white--text"},[_vm._v(" "+_vm._s(response.frm_q.title)+" ")]),_vm._v(" "+_vm._s(response.response)+" "),_c('div',{staticClass:"ma-2 pa-2 rounded-lg",staticStyle:{"background-color":"#111"}},_vm._l((response.frm_rsp_qs),function(r1,r1_index){return _c('div',{key:r1.id,staticClass:"my-5 ml-5"},[(
                        r1.frm_q.title ===
                        'Will you be lending money using personal funds?'
                      )?_c('div',[_c('span',{staticClass:"white--text"},[_vm._v("Type: "+_vm._s(r1.response))]),_vm._l((r1.frm_rsp_qs),function(r2){return _c('div',{key:r2.id},[(r1.response === 'RRSP/TFSA')?_c('span',{staticClass:"white--text"},[_vm._v(" Name of Trust Company: ")]):_vm._e(),(r1.response === 'Others')?_c('span',{staticClass:"white--text"},[_vm._v(" Specification: ")]):_vm._e(),_vm._v(" "+_vm._s(r2.response)+" ")])}),(response.frm_rsp_qs.length !== r1_index + 1)?_c('v-divider',{staticClass:"mt-5"}):_vm._e()],2):_vm._e()])}),0)]):_vm._e(),(
                  _vm.application_type_is_individual &&
                  _vm.application_type_is_also_corporation &&
                  section.title === 'Accounts and Source of Funds' &&
                  response.frm_q.title ===
                    'Will you be lending money using personal funds?'
                )?_c('div',{staticClass:"mb-5"},[_c('p',{staticClass:"mb-0 white--text"},[_vm._v(" "+_vm._s(response.frm_q.title)+" ")]),_vm._v(" "+_vm._s(response.response)+" "),_c('div',{staticClass:"ma-2 pa-2 rounded-lg",staticStyle:{"background-color":"#111"}},_vm._l((response.frm_rsp_qs),function(r1,r1_index){return _c('div',{key:r1.id,staticClass:"my-5 ml-5"},[(
                        r1.frm_q.title ===
                        'Will you be lending money using personal funds?'
                      )?_c('div',[_c('span',{staticClass:"white--text"},[_vm._v("Type: "+_vm._s(r1.response))]),_vm._l((r1.frm_rsp_qs),function(r2){return _c('div',{key:r2.id},[(r1.response === 'RRSP/TFSA')?_c('span',{staticClass:"white--text"},[_vm._v(" Name of Trust Company: ")]):_vm._e(),(r1.response === 'Others')?_c('span',{staticClass:"white--text"},[_vm._v(" Specification: ")]):_vm._e(),_vm._v(" "+_vm._s(r2.response)+" ")])}),(response.frm_rsp_qs.length !== r1_index + 1)?_c('v-divider',{staticClass:"mt-5"}):_vm._e()],2):_vm._e()])}),0)]):_vm._e(),(
                  _vm.application_type_is_individual &&
                  _vm.application_type_is_also_corporation &&
                  section.title === 'Accounts and Source of Funds' &&
                  response.frm_q.title ===
                    'Will you be lending money out of a corporation you own / control?'
                )?_c('div',{staticClass:"mb-5"},[_c('p',{staticClass:"mb-0 white--text"},[_vm._v(" "+_vm._s(response.frm_q.title)+" ")]),_vm._v(" "+_vm._s(response.response)+" "),_c('div',{staticClass:"ma-2 pa-2 rounded-lg",staticStyle:{"background-color":"#111"}},_vm._l((response.frm_rsp_qs),function(r1,r1_index){return _c('div',{key:r1.id,staticClass:"my-5 ml-5"},[_c('span',{staticClass:"mb-0 green--text"},[_vm._v(" Corporation Details #"+_vm._s(r1_index + 1)+" ")]),_vm._l((r1.frm_rsp_qs),function(r2,r2_index){return _c('div',{key:r2.id},[_c('span',{staticClass:"white--text"},[_vm._v(" "+_vm._s(_vm.corporate_details_questions[r2_index])+" : ")]),_vm._v(" "+_vm._s(r2.response)+" ")])}),(response.frm_rsp_qs.length !== r1_index + 1)?_c('v-divider',{staticClass:"mt-5"}):_vm._e()],2)}),0)]):_vm._e(),(
                  !_vm.application_type_is_individual &&
                  !_vm.application_type_is_also_corporation &&
                  section.title === 'Accounts and Source of Funds' &&
                  response.frm_q.title ===
                    'Will you be lending money out of a corporation you own / control?'
                )?_c('div',{staticClass:"mb-5"},[_c('p',{staticClass:"mb-0 white--text"},[_vm._v(" "+_vm._s(response.frm_q.title)+" ")]),_vm._v(" "+_vm._s(response.response)+" "),_c('div',{staticClass:"ma-2 pa-2 rounded-lg",staticStyle:{"background-color":"#111"}},_vm._l((response.frm_rsp_qs),function(r1,r1_index){return _c('div',{key:r1.id,staticClass:"my-5 ml-5"},[_c('span',{staticClass:"mb-0 green--text"},[_vm._v(" Corporation Details #"+_vm._s(r1_index + 1)+" ")]),_vm._l((r1.frm_rsp_qs),function(r2,r2_index){return _c('div',{key:r2.id},[_c('span',{staticClass:"white--text"},[_vm._v(" "+_vm._s(_vm.corporate_details_questions[r2_index])+" : ")]),_vm._v(" "+_vm._s(r2.response)+" ")])}),(response.frm_rsp_qs.length !== r1_index + 1)?_c('v-divider',{staticClass:"mt-5"}):_vm._e()],2)}),0)]):_vm._e()])}),(_vm.filtered_sections.length !== index + 1)?_c('v-divider'):_vm._e()],2)})],2)],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"xs":"12","lg":"8"}},[_c('v-btn',{on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" Back ")])],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }